import React, { forwardRef, useEffect, useRef } from "react";
import { Modal, ModalProps } from "react-bootstrap";
import styled, { StyleSheetManager } from "styled-components";

export const ModalView = styled(Modal)<ModalProps>`
  .big-modal-dialog {
    width: 1100px;

    @media only screen and (max-width: 1200px) {
      width: 900px;
    }

    @media only screen and (max-width: 992px) {
      width: 600px;
    }

    @media only screen and (max-width: 768px) {
      width: auto;
    }
  }

  background: hsl(210deg 50% 80% / 40%);
  backdrop-filter: blur(5px);
  display: flex;
  padding: 30px 20px;

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: scale(0.9);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .modal-dialog {
    margin: auto;
    max-height: calc(100vh - 60px);
  }

  .modal-content {
    max-height: 100%;
  }

  .modal-body {
    max-height: calc(100% - 50px - 54px);
  }
  .overlay {
    z-index: 1032;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    > i {
      font-size: 32px;
      color: #808080;
    }
  }
  .right-drawer {
    // 右ドロワーの初期値は pointer-events: none; だが、
    // 下にあるモーダルを操作しないように上書きしている。
    pointer-events: auto;
  }
`;

/**
 * bootstrap の Modal を styled-components 適用可能にするモーダルラッパー
 * SideBar と entries/* で styled-components のインスタンスが複数存在する場合、
 * bootstrap Modal の内部に styled-components が適用されません。
 * styled-components が生成する動的なクラスを HTML の head タグに生成することを
 * 明示的に指定することでスタイルが適用されるようにしています。
 */
export const StyledComponentModal = forwardRef<Modal, Omit<ModalProps, "as">>(
  (props, ref) => {
    const targetRef: React.MutableRefObject<HTMLHeadElement | null> =
      useRef(null);

    useEffect(() => {
      if (!targetRef.current) {
        targetRef.current = document.head;
      }
      return (): void => {
        if (targetRef.current) {
          targetRef.current = null;
        }
      };
    }, []);

    if (!targetRef.current) return null;

    return (
      <StyleSheetManager target={targetRef.current}>
        <ModalView {...props} ref={ref} dialogClassName="big-modal-dialog" />
      </StyleSheetManager>
    );
  },
);
