/**
 * _colors.scssからの移行
 * 様々なページで使用される色を設定しておく
 * ただし、特定の画面でのみ使用されるような色は、個々のComponentで定義する
 *
 * TODO: それぞれの色に、グローバルに分かりやすい名前をつける
 */
export default {
  main: "#253144",
  white: "#fff",
  whiteHover: "#f2f2f2", // white を 5% 暗くした色
  gray: "#ccc",
  disabled: "#bdc3c7",

  status: {
    primary: "#3264c4",
    success: "#00bfa5",
    info: "#8bc34a",
    warning: "#ffc107",
    danger: "#ef5350",
  },

  // WebUI renewal PJで使う色。(後から置換する前提)
  renewaled: {
    main: "#004acc",
    textBlack: "#020b16",
    textGrey: "#616161", // ラベルなど
    whiteHover: "#f4f4f4",
    whiteHoverActive: "#e6edfa",
    borderGrey: "#e4e8e9;",
  },
};
