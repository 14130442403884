import * as actions from "applications/report_requests/actions/reportRequests";
import RequestableExpensesSelector from "applications/report_requests/components/RequestableExpensesSelector";
import makeTransactionSelectCardStateMapper from "applications/report_requests/selectors/transactionSelectCard";
import * as transactionActions from "applications/transactions/actions/transactionTable";
import { Button } from "components/renewaled_ui/buttons";
import { StyledComponentModal } from "components/StyledComponentModal";
import i18next from "i18next";
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { isCorporatePlan } from "utilities/Utils";

/**
 * 追加可能経費モーダル
 */
const AddAttachableExpensesModal = (props): JSX.Element => {
  const isCorporate = isCorporatePlan();

  /** モーダルを閉じる */
  const handleClose = (): void => {
    props.setShowAddRequestableExpenseModal(false);
  };

  /** 選択した経費を申請書に追加 */
  const handleAttachExpenses = (): void => {
    const { reportId, selectedIds } = props;
    props.attachExpenses(reportId, selectedIds);
  };

  // アタッチ可能経費一覧を取得(= 申請、事前申請に紐づいていない経費)
  const { showAddRequestableExpenseModal, fetchExpenses } = props;
  useEffect(() => {
    if (!showAddRequestableExpenseModal) return;

    fetchExpenses();
  }, [fetchExpenses, showAddRequestableExpenseModal]);

  return (
    <StyledComponentModal
      show={props.showAddRequestableExpenseModal}
      onHide={handleClose}
      backdrop="static"
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {i18next.t("reports.titles.attachableExpenses")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: "16px" }}>
          <RequestableExpensesSelector
            expenses={props.transactions}
            selectedIds={props.selectedIds}
            modalTransaction={props.modalTransaction}
            isTransactionModalOpen={props.isTransactionModalOpen}
            projectSelectable={props.projectSelectable}
            payerSelectable={props.payerSelectable}
            fetchExpenses={props.fetchExpenses}
            resetExpenses={props.resetExpenses}
            onSortChange={props.sortRequestableExpenses}
            onClickSelectThisMonths={props.selectThisMonths}
            onClickSelectLastMonths={props.selectLastMonths}
            onSelectByProject={props.selectByProject}
            onSelectByPayer={props.selectByPayer}
            isCorporate={isCorporate}
            closeReportSendModal={props.closeReportSendModal}
            showCreateModal={props.showCreateModal}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          styleType="primary"
          disabled={!props.selectedIds.length}
          onClick={handleAttachExpenses}
        >
          {i18next.t("transactions.index.addSelectedToReport")}
        </Button>
        <Button styleType="link-secondary" onClick={handleClose}>
          {i18next.t("commons.actions.cancel")}
        </Button>
      </Modal.Footer>
      {props.isLoading && (
        <div className="overlay">
          <i className="fas fa-lg fa-spin fa-spinner loading-icon" />
        </div>
      )}
    </StyledComponentModal>
  );
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */
function mapDispatchToProps(dispatch) {
  return {
    // アタッチ可能な経費（= 経費申請、事前申請に紐づいていない経費）
    fetchExpenses(offset = 0, limit = 30) {
      return dispatch(
        actions.fetchTransactions(
          { open: true, pre_report_id: null, report_id: null }, // eslint-disable-line camelcase
          offset,
          limit,
        ),
      );
    },
    resetExpenses(offset = 0, limit = 30) {
      return dispatch(
        actions.fetchTransactions(
          { open: true, pre_report_id: null, report_id: null }, // eslint-disable-line camelcase
          offset,
          limit,
        ),
      ).then(() => {
        const ranges = [{ end: offset }, { start: offset + limit }];
        dispatch(transactionActions.clearStaleTransactions(ranges));
      });
    },
    sortRequestableExpenses(sortName, sortOrder, currentPage, sizePerPage) {
      const offset = sizePerPage * (currentPage - 1);
      const limit = sizePerPage;
      const sort = [{ key: sortName, order: sortOrder }];

      return dispatch(
        actions.sortRequestableExpenses(
          { sort, open: true, requestable: true },
          offset,
          limit,
        ),
      );
    },
    selectThisMonths() {
      dispatch(actions.selectThisMonths());
    },
    selectLastMonths() {
      dispatch(actions.selectLastMonths());
    },
    selectByProject(id) {
      dispatch(actions.selectByProject(id));
    },
    selectByPayer(id) {
      dispatch(actions.selectByPayer(id));
    },
    attachExpenses(reportId, selectedExpenseIds) {
      dispatch(actions.attachExpenses(reportId, selectedExpenseIds));
    },
  };
}

export default connect(
  makeTransactionSelectCardStateMapper,
  mapDispatchToProps,
)(AddAttachableExpensesModal);
