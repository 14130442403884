import LoadingOverlay from 'components/LoadingOverlay';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import TableColumnSelector from 'components/TableColumnSelector';
import _isNil from 'lodash/isNil';
import _omit from 'lodash/omit';
import i18next from 'i18n';
import styled from 'styled-components';
import { BootstrapTable } from 'react-bootstrap-table';

const TranslatedLabel = styled.div`
  .react-bs-table-pagination {
    .dropdown::before {
      content: ${(props) => props.label};
    }
  }
`;

export default class DataFetchingTable extends Component {
  /**
   * @todo componentDidMountで、fetchData関数をcallすることができるように
   */
  constructor(props) {
    super(props);

    this.onPageChange = this.onPageChange.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.createColumnSelector = this.createColumnSelector.bind(this);

    // TODO: currentPage, sizePerPageも管理する
    this.state = {
      isLoading: false, // テーブル内部における状態。外部でロード状態とは独立している。
    };
  }

  componentDidMount() {
    this.cacheDataOnPage(this.props.currentPage);
  }

  componentDidUpdate() {
    this.backCurrentPage();
  }

  /**
   * 再描画したページ上の件数が 0 件である場合、最終ページに戻す
   */
  backCurrentPage() {
    const { data, currentPage, sizePerPage } = this.props;
    const itemLengthInCurrentPage = this.getDataOnPage(data, currentPage).length;

    if (itemLengthInCurrentPage === 0 && sizePerPage !== 0) {
      const lastPage = Math.ceil(data.length / sizePerPage);
      if (lastPage > 0) { this.onPageChange(lastPage); }
    }
  }

  getDataOnPage(data = this.props.data, page = this.props.currenctPage, sizePerPage = this.props.sizePerPage) {
    const startIndex = (page - 1) * sizePerPage;
    return data.slice(startIndex, startIndex + sizePerPage);
  }

  /**
  * テーブル上部に表示するツールバーを構築
  */
  createColumnSelector() {
    if (this.props.columnSelectable === true) {
      const columns = this.props.children.filter((p) => p.props.selectable === true).map((p) => {
        const { children, dataField, hidden } = p.props;
        return {
          dataField,
          hidden,
          label: children,
        };
      });

      return (
        <div className="text-right" style={ { margin: '0 15px' } }>
          <TableColumnSelector
            onColumnSelectionChange={ this.props.onColumnSelectionChange }
            onRestoreDefaultColumnSelectionClick={ this.props.onRestoreDefaultColumnSelectionClick }
            columns={ columns }
            rightAligned={ true } />
        </div>
      );
    }

    return null;
  }

  /**
  * 前後のページのデータをキャッシュする
  * ボタンの表示があるページを対象にする
  *
  * @todo onSizePerPageListがcallされた時に、リクエストをキャンセルできるように
  */
  async cacheBackground(currentPage, sizePerPage = this.props.sizePerPage) {
    const { paginationSize, data } = this.props;
    const prePageSize = Math.floor((paginationSize - 1) / 2);
    const postPageSize = paginationSize - prePageSize - 1;
    const targetPrePage = Math.max(1, currentPage - prePageSize);
    const targetPostPage = Math.min(currentPage + postPageSize, Math.floor(data.length / sizePerPage));

    for (let i = currentPage - 1; i >= targetPrePage; i--) {
      await this.cacheDataOnPage(i, sizePerPage, true);
    }

    for (let i = currentPage + 1; i <= targetPostPage; i++) {
      await this.cacheDataOnPage(i, sizePerPage, true);
    }
  }

  cacheDataOnPage(page, sizePerPage = this.props.sizePerPage, background = false) {
    const dataOnPage = this.getDataOnPage(this.props.data, page, sizePerPage);

    if (dataOnPage.some((x) => _isNil(x))) {
      const startIndex = (page - 1) * sizePerPage;

      if (!background) {
        this.setState({ isLoading: true });
      }

      return this.props.fetchData(startIndex, sizePerPage, background)
        .then(() => {
          if (!background) {
            this.setState({ isLoading: false });
          }
        })
        .catch((e) => {
          if (!background) {
            this.setState({ isLoading: false });
          }

          return Promise.reject(e);
        });
    }

    return Promise.resolve();
  }

  onPageChange(page, sizePerPage) {
    if (this.props.isLoading || this.state.isLoading) {
      // データロード中は、ページ移動をさせない
      // ページ移動を連続されると、ページを行ったり来たりするような表示になる症状を軽減させるため
      return;
    }

    // 必要なデータを取得してから、表示ページを切り替える
    // 切り替え後に、前後のページをキャッシュしておく
    this.cacheDataOnPage(page, sizePerPage)
      .then(() => {
        // @todo onPageChangeの実行が並列した時、後から呼ばれたものによる呼び出しが優先するように
        //   this.props.onPageChangeの実行順が不定のため、ページが行ったり来たりすることがある
        this.props.onPageChange(page);
        return Promise.resolve();
      })
      .then(() => {
        this.cacheBackground(page, sizePerPage);
      });
  }

  onSortChange(sortName, sortOrder) {
    const { data, currentPage, sizePerPage } = this.props;

    if (this.refs.table && this.refs.table.body) {
      const tableBody = ReactDOM.findDOMNode(this.refs.table.body);
    }

    this.setState({ isLoading: true });

    this.props.onSortChange(sortName, sortOrder, currentPage, sizePerPage)
      .then(() => {
        this.setState({ isLoading: false });
        this.cacheBackground(currentPage, sizePerPage);
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        return Promise.reject(e);
      });
  }

  buildProps() {
    const originalProps = _omit(this.props, [
      'data',
      'currentPage',
      'fetchData',
      'onSizePerPageList',
      'sizePerPage',
      'sizePerPageList',
      'columnSelectable',
      'paginationSize',
      'hideSizePerPage',
      'hidePageListOnlyOnePage',
      'onPageChange',
    ]);

    // 表示するのに必要なデータが未取得のものは表示しない
    const data = this.getDataOnPage(this.props.data, this.props.currentPage)
      .filter((x) => x !== void 0);

    const options = {
      ...(this.props.options || {}),
      page: this.props.currentPage,
      sizePerPageList: this.props.sizePerPageList,
      sizePerPage: this.props.sizePerPage,
      paginationSize: this.props.paginationSize,
      hideSizePerPage: this.props.hideSizePerPage,
      hidePageListOnlyOnePage: this.props.hidePageListOnlyOnePage,
      onPageChange: this.onPageChange,
      onSizePerPageList: this.props.onSizePerPageList,
      onSortChange: this.onSortChange,
    };

    if (this.props.columnSelectable) {
      options.toolBar = this.createColumnSelector;
    }

    return {
      ...originalProps,
      containerStyle: {
        marginBottom: '32px',
        ...(this.props.containerStyle || {}),
      },
      data,
      fetchInfo: { dataTotalSize: this.props.data.length },
      pagination: true,
      remote: true,
      expandableRow: this.props.expandableRow,
      expandComponent: this.props.expandComponent,
      options,
    };
  }

  render() {
    return (
      <LoadingOverlay show={ this.props.isLoading || this.state.isLoading }
        iconClassName={ 'fa fa-spinner fa-spin fa-fw fa-3x' }
      >
        <TranslatedLabel label={ `"${i18next.t('commons.words.sizePerPage')}"` }>
          <BootstrapTable ref='table' { ...this.buildProps() }>
            { this.props.children }
          </BootstrapTable>
        </TranslatedLabel>
      </LoadingOverlay>
    );
  }
}

DataFetchingTable.defaultProps = {
  data: [],
  options: {},
  isLoading: false,
  currentPage: 1,
  /**
   * @param {number} offset 取得するデータの開始位置（i.e. n番目〜）
   * @param {number} limit 取得するデータの個数
   * @return {Promise}
   */
  fetchData(offset, limit) {
    return Promise.resolve();
  },
  onSizePerPageList(sizePerPage) {},
  sizePerPage: 30,
  sizePerPageList: [10, 30, 50],
  paginationSize: 5,
  hideSizePerPage: false,
  hidePageListOnlyOnePage: false,
  onPageChange(page) {},
  /**
   * @return {Promise}
   */
  onSortChange(sortName, sortOrder) {
    return Promise.resolve();
  },
  expandableRow() { return false; },
  expandComponent() { return null; },
  columnSelectable: false,
  onColumnSelectionChange(columnVisibilities) {},
  onRestoreDefaultColumnSelectionClick() {},
};

DataFetchingTable.propTypes = {
  // data.lengthは全体のサイズになる（要素は、サーバーから取得完了していないものはundefinedになる）
  data: PropTypes.array.isRequired,
  options: PropTypes.object,
  // loadingOverlayを外部から表示するために使う。初回にデータを取得する際や、再取得する際などに使用
  isLoading: PropTypes.bool,
  fetchData: PropTypes.func.isRequired,
  onSizePerPageList: PropTypes.func, // sizePerPageList.length > 1の時は必須
  sizePerPage: PropTypes.number.isRequired, // ページあたりの表示件数
  currentPage: PropTypes.number.isRequired, // 現在開いているページ
  sizePerPageList: PropTypes.arrayOf(PropTypes.number).isRequired,
  paginationSize: PropTypes.number,
  hideSizePerPage: PropTypes.bool,
  hidePageListOnlyOnePage: PropTypes.bool,
  onPageChange: PropTypes.func.isRequired,
  onSortChange: PropTypes.func, // sort機能を使用する時は必須。古いキャッシュを削除したり、検索条件を保存する処理を行う
  expandableRow: PropTypes.func, // テーブルの拡縮機能を用いるか、bool値を返すfunc、基本はfalse
  expandComponent: PropTypes.func, // 拡大時のテーブル表示ロジック
  columnSelectable: PropTypes.bool, // 表示列選択ドロップダウンの有効/無効
  onColumnSelectionChange: PropTypes.func, // 表示列選択ドロップダウンの項目押下時に実行するfunc
  // 表示列選択ドロップダウン内の「初期設定に戻す」を押下時に実行にするfunc
  onRestoreDefaultColumnSelectionClick: PropTypes.func,
};
