import * as actions from "applications/report_requests/actions/reportRequests";
import RequestableExpensesSelector from "applications/report_requests/components/RequestableExpensesSelector";
import { RequestablePreReportSelector } from "applications/report_requests/components/RequestablePreReportSelector";
import makeTransactionSelectCardStateMapper from "applications/report_requests/selectors/transactionSelectCard";
import * as transactionActions from "applications/transactions/actions/transactionTable";
import { Button } from "components/renewaled_ui/buttons";
import { TabPanel, Tabs } from "components/renewaled_ui/Tab";
import { StyledComponentModal } from "components/StyledComponentModal";
import i18next from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import Api from "utilities/api";
import { PreReport } from "utilities/api/models";
import flash from "utilities/flash";
import { isCorporatePlan } from "utilities/Utils";
import { SaveAsDraftButton } from "./SaveAsDraftButton";

/**
 * 新規経費精算モーダル
 */
const ReportCreateModal = (props): JSX.Element => {
  const [selectedTabNum, setSelectedTabNum] = useState(1);
  const isCorporate = isCorporatePlan();
  const [isFetching, setIsFetching] = useState(false);
  const [preReports, setPreReports] = useState<PreReport[]>([]);

  const tabs = useMemo(
    () => [
      {
        index: 1,
        label: i18next.t(
          "reports.properties.reportRequestTypes.chooseFromExpenses",
        ),
      },
      {
        index: 2,
        label: i18next.t(
          "reports.properties.reportRequestTypes.chooseFromPreReports",
        ),
      },
    ],
    [],
  );

  const { showCreateModal, fetchExpenses } = props;

  /**
   * 申請可能経費一覧を取得
   */
  useEffect(() => {
    if (!showCreateModal) return;

    fetchExpenses();
  }, [fetchExpenses, showCreateModal]);

  const preReportRequestTypeId = (): string | undefined => {
    const preReportRequest = userPreferences.preference.requestTypes.find(
      (x) => x.type === "pre_report_request",
    );
    return preReportRequest?.id;
  };

  /**
   * 申請可能事前申請一覧を取得
   */
  useEffect(() => {
    if (!showCreateModal) return;

    const fetchPreReports = async (): Promise<void> => {
      if (!preReportRequestTypeId()) return;

      const params = {
        for: "reportable",
        request_types: [preReportRequestTypeId()], // eslint-disable-line camelcase
      };

      try {
        setIsFetching(true);
        const res = await Api.requests.search(params);
        setPreReports(res[0].results);
      } catch (e) {
        if (e instanceof Error) {
          flash.error(
            e.message || i18next.t("commons.errors.communicationError"),
          );
        } else {
          flash.error(i18next.t("commons.errors.communicationError"));
        }
      }

      setIsFetching(false);
    };

    fetchPreReports();
  }, [showCreateModal]);

  /**
   * 申請するボタン
   */
  const renderReportSendButton = (): JSX.Element => {
    return (
      <>
        {props.selectedIds.length ? (
          <Button
            styleType="primary"
            onClick={(): void => props.openReportSendModal(props.selectedIds)}
          >
            {i18next.t(
              `reports.requests.${
                isCorporate ? "submitSelected" : "createReportOfSelected"
              }`,
            )}
          </Button>
        ) : (
          <Button
            styleType="primary"
            onClick={(): void => props.openReportSendModal(props.selectedIds)}
          >
            {i18next.t(
              `reports.requests.${
                isCorporate ? "submitAll" : "createReportOfAll"
              }`,
            )}
          </Button>
        )}
      </>
    );
  };

  return (
    <StyledComponentModal
      show={props.showCreateModal}
      onHide={(): void => {
        props.setShowCreateModal(false);
      }}
      backdrop="static"
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{i18next.t("reports.titles.reportRequest")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          value={selectedTabNum}
          tabs={tabs}
          onSelect={(v: number): void => setSelectedTabNum(v)}
        />
        <div style={{ padding: "16px" }}>
          <TabPanel value={selectedTabNum} selectedIndex={1}>
            <RequestableExpensesSelector
              expenses={props.transactions}
              selectedIds={props.selectedIds}
              modalTransaction={props.modalTransaction}
              isTransactionModalOpen={props.isTransactionModalOpen}
              isReportSendModalOpen={props.isReportSendModalOpen}
              projectSelectable={props.projectSelectable}
              payerSelectable={props.payerSelectable}
              fetchExpenses={props.fetchExpenses}
              resetExpenses={props.resetTransactions}
              onSortChange={props.sortRequestableExpenses}
              onClickSelectThisMonths={props.selectThisMonths}
              onClickSelectLastMonths={props.selectLastMonths}
              onSelectByProject={props.selectByProject}
              onSelectByPayer={props.selectByPayer}
              isCorporate={isCorporate}
              closeReportSendModal={props.closeReportSendModal}
              showCreateModal={props.showCreateModal}
            />
          </TabPanel>
          <TabPanel value={selectedTabNum} selectedIndex={2}>
            <RequestablePreReportSelector
              preReports={preReports}
              isFetching={isFetching}
            />
          </TabPanel>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {selectedTabNum === 1 && renderReportSendButton()}
        {selectedTabNum === 1 &&
          userPreferences.preference.enableReportDraftSave && (
            <SaveAsDraftButton
              onSaveDraft={(): void =>
                props.openReportSendModal(props.selectedIds, true)
              }
              selectedIds={props.selectedIds}
            />
          )}
        <Button
          styleType="link-secondary"
          onClick={(): void => props.setShowCreateModal(false)}
        >
          {i18next.t("commons.actions.cancel")}
        </Button>
      </Modal.Footer>
      {props.isLoading && (
        <div className="overlay">
          <i className="fas fa-lg fa-spin fa-spinner loading-icon" />
        </div>
      )}
    </StyledComponentModal>
  );
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */
function mapDispatchToProps(dispatch) {
  return {
    fetchExpenses(offset = 0, limit = 30) {
      return dispatch(
        actions.fetchTransactions(
          { open: true, requestable: true },
          offset,
          limit,
        ),
      );
    },
    resetTransactions(offset = 0, limit = 30) {
      return dispatch(
        actions.fetchTransactions(
          { open: true, requestable: true },
          offset,
          limit,
        ),
      ).then(() => {
        const ranges = [{ end: offset }, { start: offset + limit }];
        dispatch(transactionActions.clearStaleTransactions(ranges));
      });
    },
    sortRequestableExpenses(sortName, sortOrder, currentPage, sizePerPage) {
      const offset = sizePerPage * (currentPage - 1);
      const limit = sizePerPage;
      const sort = [{ key: sortName, order: sortOrder }];

      return dispatch(
        actions.sortRequestableExpenses(
          { sort, open: true, requestable: true },
          offset,
          limit,
        ),
      );
    },
    selectThisMonths() {
      dispatch(actions.selectThisMonths());
    },
    selectLastMonths() {
      dispatch(actions.selectLastMonths());
    },
    selectByProject(id) {
      dispatch(actions.selectByProject(id));
    },
    selectByPayer(id) {
      dispatch(actions.selectByPayer(id));
    },
    openReportSendModal(transactionIds = null, isDraft = false) {
      dispatch(actions.openReportSendModal(transactionIds, isDraft));
    },
    closeReportSendModal() {
      dispatch(actions.toggleReportSendModal(false));
    },
  };
}

export default connect(
  makeTransactionSelectCardStateMapper,
  mapDispatchToProps,
)(ReportCreateModal);
